<template>
  <div class="lesiurpackages mt-3" :class="{ 'left-align': !isRTLPage }">
    <div class="container">
      <a :href="bannerHref" v-if="showBanner">
        <div
          class="lesiurpackages_head"
          :style="
            bannerImage
              ? `background-image: url(${bannerImage})`
              : ''
          "
        >
          <!-- <h1>{{ $t("home.slide-deal.leisure-packages") }}</h1> -->
        </div>
      </a>

      <b-tabs>
        <!-- <div class="lesiurtabFilter">
          <LesiurtabFilter
            :options="filterListOptions"
            @changeFilter="filterByOption"
            @forceUpdate="forceUpdate"
          />
        </div> -->

        <MobileFilter
          :sortButtonList="mobileSortButtonList"
          :optionsHome="filterListOptions"
          @filterChange="filterByOption"
          @forceUpdate="forceUpdate"
          :isHome="`home`"
        />
        <b-tab v-for="(dealListItem, index) in dealList" :key="index" :active="index === activeIndex" @click="ev => changeTab(index)">
          <template #title>
            <div :class="{'tab-title' :dealList.length > 4 }"><p>{{ dealListItem.name }}</p></div>
          </template>
          <b-card-text>
            <LesiurtabContent :dealListItem="dealListItem" :index="index" :active="index === activeIndex" :activeIndex="activeIndex" :filterConditions="getFilterConditions" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import TemplateLesiurtabs from './TemplateLesiurtabs';

export default {
  name: 'LesiurtabsTheme0',
  mixins: [TemplateLesiurtabs],
  components: {
    LesiurtabContent: () => import('@/components/home/lesiurtabContent/LesiurtabContentTheme0'),
    // LesiurtabFilter: () => import('@/components/home/LesiurtabFilter'),
    MobileFilter: () => import('@/components/searchResult/MobileFilter'),
    BTabs,
    BTab,
    BCardText,
  },
};
</script>
<style>
.lesiurpackages ul.nav.nav-tabs {
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  direction: rtl;
}
.lesiurpackages.left-align ul.nav.nav-tabs {
  direction: ltr;
}
.lesiurpackages ul.nav.nav-tabs li:first-child {
  border-right: none;
}
.lesiurpackages ul.nav.nav-tabs li {
  padding: 0 0;
  margin: 0 0;
  position: relative;
  border-right: 1px solid #bbb;
  padding-left: 10px;
  text-transform: capitalize;
}
.lesiurpackages ul.nav.nav-tabs li a.active {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li a:hover {
  background: #005fa8;
  color: #fff;
}
.lesiurpackages ul.nav.nav-tabs li:first-child a {
  margin: 0 0;
}
.lesiurpackages ul.nav.nav-tabs li a {
  border: none;
  padding: 8px 18px;
  margin: 0 10px 0 0;
  background: transparent;
  border-radius: 5px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
}
.lesiurpackages ul.nav.nav-tabs li a.active:after {
  display: block;
  background: #005fa8;
}
.lesiurpackages ul.nav.nav-tabs li a:after {
  display: none;
  content: " ";
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: #005fa8;
  left: -4px;
  bottom: -4px;
  margin: 0 50%;
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  -webkit-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}
.lesiurpackages .tab-content {
  border: none;
}
.lesiurtabFilter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 479px) {
  .lesiurpackages_head h1 {
    font-size: 18px;
  }
  .lesiurpackages_head {
    min-height: auto;
    padding: 20px 20px;
  }
  .lesiurpackages ul.nav.nav-tabs li a {
    padding: 6px 8px;
    font-size: 14px;
    margin-right: 5px;
  }
  .lesiurpackages ul.nav.nav-tabs {
    padding-right: 0px;
  }
  .lesiurpackages_hotelsbox.big {
    height: 300px;
  }
  .lesiurpackages ul.nav.nav-tabs li {
    padding-left: 5px;
    max-width: 20rem;
    white-space: nowrap;
  }
  /* .tab-title{
    width: min-content;
  } */
  .lesiurpackages ul.nav.nav-tabs li a.active:after{
    display: none;
  }
  .tab-title {
    width: fit-content;
  }
}
</style>
